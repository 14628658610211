import React from 'react';

interface ButtonProps {
  onClick?: any;
  children: any;
  disabled?: boolean;
  className?: string;
}

const Button = ({ onClick, children, className, disabled }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={
        `bg-hopstack-blue-700 flex gap-2 h-fit rounded-lg text-white py-2 px-4 hover:opacity-80 ` +
        className
      }
    >
      {children}
    </button>
  );
};

export default Button;
