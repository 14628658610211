import React from 'react'
import { Organization } from './types'
import { roles } from './utils'

interface Context {
  organization: Organization
  setOrganization: any
  showNotification: {
    show: boolean
    type: string
    content: string
  }
  setShowNotification: any
  loading: boolean
  setLoading: any
  orgRole: roles
  setOrgRole: any
}
const BaseContext = React.createContext<Context>({
  organization: null,
  setOrganization: null,
  showNotification: { show: false, type: '', content: '' },
  setShowNotification: null,
  loading: false,
  setLoading: null,
  orgRole: null,
  setOrgRole: null,
})

export default BaseContext
