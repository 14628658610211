import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useRequest from '../hooks/useRequest';
import { RequestParams } from '../hooks/useRequest';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import BaseContext from '../components/common/BaseContext';

import { apiPaths } from '../utils/ApiPaths';
import { Product, Warehouse } from '../components/common/types';
import { getMarketplace } from '../utils/ConnectionConfig';
import { Integration, IntegrationName } from './ConnectionDetails';
import Button from '../components/common/Button';

export const fulfillmentDetailNavigation = [
  { name: 'Box' },
  { name: 'Pallet' },
  { name: 'Rate Shopping' },
  { name: 'Product Selection' },
];

export const newBox = {
  weight: null,
  height: null,
  width: null,
  length: null,
  weightUnit: 'pounds',
  dimensionUnit: 'inches',
};

export interface Order {
  outboundOrderId: string;
  merchantId: string;
  integrationId: string;
  integrationConnectionId: string;
  status: string;
  lineItems: {
    listingId: string;
    quantity: number;
    _id: string;
    listing: Product;
  }[];
  marketplaceData: {
    orderId: string;
    orderDisplayId: string;
    shopifyGraphQlId: string;
    displayFinancialStatus: string;
  };
  marketplaceError: any;
  isDeleted: boolean;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  merchant: {
    name: string;
    merchantId: string;
    organizationId: string;
    email: string;
    status: string;
    isDeleted: boolean;
    deletedAt: string | null;
    createdAt: string;
    updatedAt: string;
  };
  warehouse?: Warehouse;
  integration?: Integration;
}

/**
 * Formats an address into a string.
 * @param address The address to format.
 * @returns The formatted address string.
 */
export function formatAddress(address: any): string {
  if (!address || Object.keys(address).length === 0) {
    return 'N/A';
  }

  let formattedAddress: string[] = [];

  // Handle the first format
  if (address.Name) {
    formattedAddress = [
      address.Name,
      address.AddressLine1,
      `${address.City}, ${address.StateOrProvinceCode} ${address.PostalCode}`,
      address.CountryCode,
    ];
  }
  // Handle the second format
  else {
    formattedAddress = [
      address.name,
      address.line1,
      address.line2,
      address.line3,
      `${address.city || ''}, ${address.state || ''} ${address.zipCode || ''}${
        address.zipCodeSuffix ? `-${address.zipCodeSuffix}` : ''
      }`,
      address.country.name || address.country,
    ];
  }

  return formattedAddress.filter((line) => line).join('\n');
}

const OutboundDetail = () => {
  const { setLoading, organization, setShowNotification, setOrganization } =
    useContext(BaseContext);

  const urlParams = useParams();
  const navigate = useNavigate();

  const { executeRequest: fetchOrganizationsData } = useRequest(
    `${apiPaths.GET_ORGANIZATIONS}`,
    [],
  );
  const { executeRequest: fetchOrderData, data: order } = useRequest<Order>(
    `${apiPaths.GET_OUTBOUND_ORDER}`,
  );
  const { executeRequest: createShopifyFulfillment } = useRequest(
    '/api/v1/outbound-orders/{outboundOrderId}/fulfillment/shopify',
    {},
  );

  const fetchOrder = async () => {
    try {
      setLoading(true);
      const orderParams: RequestParams = {
        queryParams: { include: 'merchant,listing,integration' },
        urlParams: { orderId: urlParams.orderId },
      };
      await fetchOrderData(orderParams);
    } catch (error) {
      console.log('Error fetching order', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      if (urlParams.orgId && !organization) {
        (async () => {
          const organizationParams: RequestParams = {
            queryParams: { include: 'subscription' },
            urlParams: {},
          };
          const organizationsResponse = await fetchOrganizationsData(organizationParams);
          if (organizationsResponse.success && organizationsResponse.data) {
            const org = organizationsResponse.data.find(
              (org) => org.organizationId === urlParams.orgId,
            );
            setOrganization(org);
            if (!org) location.replace('/');
          } else location.replace('/');
        })();
      }
    } catch (error) {
      console.log('Error fetching organizations data', error);
      location.replace('/');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (urlParams.orderId) {
      fetchOrder();
    }
  }, [urlParams.orderId]);

  async function handleShopifyFulfillmentCreation(outboundOrderId: string) {
    try {
      const fulfillmentResponse = await createShopifyFulfillment(
        {
          urlParams: { outboundOrderId },
          queryParams: {},
        },
        {
          method: 'POST',
        },
      );

      if (fulfillmentResponse.success) {
        setShowNotification({
          show: true,
          type: 'success',
          content: 'Created fulfillment',
        });
      } else {
        throw new Error(fulfillmentResponse.message);
      }
    } catch (error) {
      console.error('Error creating fulfillment:', error);
      setShowNotification({
        show: true,
        type: 'error',
        content: error.message || 'Failed to create fulfillment. Please try again.',
      });
    }
  }

  return (
    <div className='p-8'>
      <div className='flex justify-between my-2'>
        <div className='flex gap-2'>
          <h1
            className='text-[#030229] text-[24px] cursor-pointer font-bold hover:underline'
            onClick={() => {
              navigate(-1);
            }}
          >
            Outbound Order{' '}
          </h1>
          <span className='my-auto font-semibold text-[18px]'>{' > Order Details'}</span>
        </div>
        {order?.integration.integrationName === IntegrationName.SHOPIFY &&
          !['completed', 'cancelled'].includes(order.status) && (
            <Button
              onClick={() => {
                handleShopifyFulfillmentCreation(order.outboundOrderId);
              }}
            >
              <div>Create Fulfillment</div>
            </Button>
          )}
      </div>

      <div className='grid grid-cols-4 gap-3 mt-8'>
        <div>
          <div className='text-[#717679] text-[14px]'>Outbound Order ID</div>
          <div className='text-[#344053] text-[15px]  line-clamp-3 '>{order?.outboundOrderId}</div>
        </div>
        <div>
          <div className='text-[#717679] text-[14px]'>Client</div>
          <div className='text-[#344053] text-[15px] capitalize'>{order?.merchant?.name}</div>
        </div>
      </div>

      <div className='flex gap-4 my-4'>
        <div className='w-full'>
          <label htmlFor='search' className='sr-only'>
            Search
          </label>
          <div className='relative text-gray-400 focus-within:text-gray-600 border border-gray-300 shadow-sm w-full rounded-md'>
            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
              <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
            </div>
            <input
              id='search'
              className='block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-hopstack-blue-600 sm:text-sm sm:leading-6'
              placeholder='Search'
              type='search'
              name='search'
            />
          </div>
        </div>
      </div>
      <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
        <table className='min-w-full divide-y divide-gray-300'>
          <thead className='bg-gray-50'>
            <tr>
              <th
                scope='col'
                className='py-3.5  pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
              >
                Image
              </th>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 max-w-[100px] text-left text-sm font-semibold text-gray-900 sm:pl-6'
              >
                Product Name
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 text-balance text-sm font-semibold text-gray-900'
              >
                Identifiers
              </th>
              <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                Order Qty.
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-200 bg-white'>
            {order?.lineItems?.map((lineItem, index) => {
              const product = lineItem.listing;
              return (
                <tr key={index}>
                  <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
                    {product.images && product.images.length > 0 && (
                      <img
                        className='object-scale-down h-20'
                        src={product?.images[0]}
                        height={100}
                        width={100}
                      />
                    )}
                  </td>
                  <td className='py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 max-w-[20rem]'>
                    {/* <div className='has-tooltip max-w-prose relative'>
                      <span className='tooltip rounded bottom-7 shadow-xl text-[12px] p-1 bg-[#1c1c1c] text-white z-40'>
                        {product.name}
                      </span>
                      {product?.name} <br />
                    </div> */}
                    <div className='line-clamp-3 '>{product.name}</div>
                  </td>
                  <td className='px-3 py-4 text-sm text-gray-500 mx-auto'>
                    <div className='w-48 text-wrap mx-auto'>
                      {product?.marketplaceData?.marketplaceId &&
                        product?.marketplaceData?.asin && (
                          <a
                            href={`${
                              getMarketplace(product.marketplaceData.marketplaceId).marketplaceUrl
                            }/dp/${product.marketplaceData.asin}`}
                            target='blank'
                            className='hover:underline'
                          >
                            {' '}
                            <span className='font-bold'>ASIN: </span>
                            {product.marketplaceData.asin + '\n'}
                          </a>
                        )}
                      <br />
                      {product?.marketplaceData?.fnSku && (
                        <>
                          <span className='font-bold'>FNSKU: </span>
                          {product.marketplaceData.fnSku + '\n'}
                          <br />
                        </>
                      )}
                      {product?.marketplaceData?.sellerSku && (
                        <>
                          <span className='font-bold'>Seller SKU: </span>
                          {product.marketplaceData.sellerSku + '\n'}
                          <br />
                        </>
                      )}
                    </div>
                  </td>
                  <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                    {lineItem?.quantity || 0}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OutboundDetail;
